var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b10-base',[_c('ValidationProvider',{attrs:{"name":"fnovedad","rules":_vm.formRules.fnovedad},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-date-time-picker',{attrs:{"title":"Fecha y hora","error-messages":errors},model:{value:(_vm.form.fnovedad),callback:function ($$v) {_vm.$set(_vm.form, "fnovedad", $$v)},expression:"form.fnovedad"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"estado","rules":_vm.formRules.estado},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.estados,"item-value":"idlparte_novedad_estado","item-text":"descripcion","label":"Estado","clearable":"","error-messages":errors},model:{value:(_vm.form.estado),callback:function ($$v) {_vm.$set(_vm.form, "estado", $$v)},expression:"form.estado"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"tnovedad","rules":_vm.formRules.tnovedad},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.tnovedades,"item-text":"descripcion","return-object":"","label":"Tipo de novedad","clearable":"","error-messages":errors},on:{"change":_vm.tnovedadSelected},model:{value:(_vm.form.tnovedad),callback:function ($$v) {_vm.$set(_vm.form, "tnovedad", $$v)},expression:"form.tnovedad"}})]}}])}),_c('b10-autocomplete',{attrs:{"items":_vm.fuerzasSeguridad,"item-value":"idfuerza_seguridad","item-text":"descripcion","label":"Notificado a","clearable":""},model:{value:(_vm.form.idfuerza_seguridad),callback:function ($$v) {_vm.$set(_vm.form, "idfuerza_seguridad", $$v)},expression:"form.idfuerza_seguridad"}}),_c('ValidationProvider',{attrs:{"name":"descripcion","rules":_vm.formRules.descripcion},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-textarea',{attrs:{"label":"Descripción","error-messages":errors},model:{value:(_vm.form.descripcion),callback:function ($$v) {_vm.$set(_vm.form, "descripcion", $$v)},expression:"form.descripcion"}})]}}])}),_vm._l((_vm.form.datosNovedad),function(item){return _c('div',{key:item.idtdato_tnovedad},[(item.tdato_novedad_tipo_valor === _vm.tiposTdatoNovedad.tipos.numero || item.tdato_novedad_tipo_valor === _vm.tiposTdatoNovedad.tipos.texto)?_c('v-text-field',{attrs:{"clearable":"","label":item.tdato_novedad_descripcion,"rules":_vm.tdatoObligatorioRule(item)},model:{value:(item.valor),callback:function ($$v) {_vm.$set(item, "valor", $$v)},expression:"item.valor"}}):_vm._e(),(item.tdato_novedad_tipo_valor === _vm.tiposTdatoNovedad.tipos.fecha)?_c('b10-date-picker',{attrs:{"clearable":"","title":item.tdato_novedad_descripcion,"rules":_vm.tdatoObligatorioRule(item)},model:{value:(item.valor),callback:function ($$v) {_vm.$set(item, "valor", $$v)},expression:"item.valor"}}):_vm._e()],1)}),_c('ValidationProvider',{attrs:{"name":"punto","rules":_vm.formRules.punto,"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"input-punto",attrs:{"label":"Punto","type":"text","autocomplete":"off","clearable":"","error-messages":errors},on:{"change":_vm.changePunto},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [(_vm.hasNFC)?_c('v-icon',{staticClass:"mt-1 ml-1",on:{"click":_vm.clickScanNFC}},[_vm._v(" "+_vm._s(_vm.$vuetify.icons.values.nfc)+" ")]):_vm._e(),_c('v-icon',{staticClass:"mt-1 ml-3",on:{"click":_vm.clickQR}},[_vm._v(" "+_vm._s(_vm.$vuetify.icons.values.qr)+" ")])]},proxy:true}],null,true),model:{value:(_vm.form.punto),callback:function ($$v) {_vm.$set(_vm.form, "punto", $$v)},expression:"form.punto"}})]}}])}),_c('v-text-field',{attrs:{"type":"number","label":"Latitud","disabled":""},model:{value:(_vm.form.latitud),callback:function ($$v) {_vm.$set(_vm.form, "latitud", _vm._n($$v))},expression:"form.latitud"}}),_c('v-text-field',{attrs:{"type":"number","label":"Longitud","disabled":""},model:{value:(_vm.form.longitud),callback:function ($$v) {_vm.$set(_vm.form, "longitud", _vm._n($$v))},expression:"form.longitud"}}),(_vm.form.latitud && _vm.form.longitud)?_c('v-btn',{attrs:{"block":"","color":"secondary","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickIrMapa(_vm.form.latitud, _vm.form.longitud)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.$vuetify.icons.values.mapLocation)+" ")]),_vm._v(" Localización ")],1):_vm._e(),_c('b10-qr-reader',{attrs:{"showing":_vm.showingDialogs.scanQR,"title":"Leer código QR"},on:{"update:showing":function($event){return _vm.$set(_vm.showingDialogs, "scanQR", $event)},"decode":_vm.decodeQR}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }