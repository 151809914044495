<template>
  <b10-base>
    <ValidationProvider
      v-slot="{ errors }"
      name="fnovedad"
      :rules="formRules.fnovedad"
    >
      <b10-date-time-picker
        v-model="form.fnovedad"
        title="Fecha y hora"
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="estado"
      :rules="formRules.estado"
    >
      <b10-autocomplete
        v-model="form.estado"
        :items="estados"
        item-value="idlparte_novedad_estado"
        item-text="descripcion"
        label="Estado"
        clearable
        :error-messages="errors"
      />
    </ValidationProvider>
    <ValidationProvider
      v-slot="{ errors }"
      name="tnovedad"
      :rules="formRules.tnovedad"
    >
      <b10-autocomplete
        v-model="form.tnovedad"
        :items="tnovedades"
        item-text="descripcion"
        return-object
        label="Tipo de novedad"
        clearable
        :error-messages="errors"
        @change="tnovedadSelected"
      />
    </ValidationProvider>
    <b10-autocomplete
      v-model="form.idfuerza_seguridad"
      :items="fuerzasSeguridad"
      item-value="idfuerza_seguridad"
      item-text="descripcion"
      label="Notificado a"
      clearable
    />
    <ValidationProvider
      v-slot="{ errors }"
      name="descripcion"
      :rules="formRules.descripcion"
    >
      <b10-textarea
        v-model="form.descripcion"
        label="Descripción"
        :error-messages="errors"
      />
    </ValidationProvider>
    <!-- datos dinámicos según tipo de novedad -> TDATO_TNOVEDAD -->
    <div
      v-for="item in form.datosNovedad"
      :key="item.idtdato_tnovedad"
    >
      <v-text-field
        v-if="item.tdato_novedad_tipo_valor === tiposTdatoNovedad.tipos.numero || item.tdato_novedad_tipo_valor === tiposTdatoNovedad.tipos.texto"
        v-model="item.valor"
        clearable
        :label="item.tdato_novedad_descripcion"
        :rules="tdatoObligatorioRule(item)"
      />
      <b10-date-picker
        v-if="item.tdato_novedad_tipo_valor === tiposTdatoNovedad.tipos.fecha"
        v-model="item.valor"
        clearable
        :title="item.tdato_novedad_descripcion"
        :rules="tdatoObligatorioRule(item)"
      />
    </div>
    <ValidationProvider
      v-slot="{ errors }"
      name="punto"
      :rules="formRules.punto"
      mode="lazy"
    >
      <v-text-field
        v-model="form.punto"
        class="input-punto"
        label="Punto"
        type="text"
        autocomplete="off"
        clearable
        :error-messages="errors"
        @change="changePunto"
      >
        <template #append-outer>
          <v-icon
            v-if="hasNFC"
            class="mt-1 ml-1"
            @click="clickScanNFC"
          >
            {{ $vuetify.icons.values.nfc }}
          </v-icon>
          <v-icon
            class="mt-1 ml-3"
            @click="clickQR"
          >
            {{ $vuetify.icons.values.qr }}
          </v-icon>
        </template>
      </v-text-field>
    </ValidationProvider>
    <v-text-field
      v-model.number="form.latitud"
      type="number"
      label="Latitud"
      disabled
    />
    <v-text-field
      v-model.number="form.longitud"
      type="number"
      label="Longitud"
      disabled
    />
    <v-btn
      v-if="form.latitud && form.longitud"
      block
      color="secondary"
      dark
      @click.stop="clickIrMapa(form.latitud, form.longitud)"
    >
      <v-icon
        left
      >
        {{ $vuetify.icons.values.mapLocation }}
      </v-icon> Localización
    </v-btn>
    <b10-qr-reader
      :showing.sync="showingDialogs.scanQR"
      title="Leer código QR"
      @decode="decodeQR"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './LparteNovedadFormData'
import { getLatLongURL } from '@/utils/maps'
import { TDATO_NOVEDAD, LPARTE_NOVEDAD } from '@/utils/consts'
import { currentDateTime } from '@/utils/date'
import _ from '@/utils/lodash'
import { hasNFC, scanNFC } from '@/utils/nfc'
import  { extend } from 'vee-validate'

export default {
  mixins: [formMixin],
  props: {
    geolocalizacion: {
      type: Object,
      default: () => {},
    },
    puntoObligatorio: {
      type: Boolean,
      default: false,
    },
    idpuestoServicio: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      tnovedades: [],
      fuerzasSeguridad: [],
      estados: [],
      form: {
        fnovedad: currentDateTime(),
        tnovedad: null,
        idfuerza_seguridad: null,
        estado: LPARTE_NOVEDAD.estados.pendiente,
        informe_cliente: false,
        descripcion: '',
        datosNovedad: [],
        latitud: null,
        longitud: null,
        punto: '',
        idpunto: null,
      },
      formRules: {
        fnovedad: { required: true },
        estado: { required: true },
        tnovedad: { required: true },
        descripcion: { required: true },
        punto: { required: this.puntoObligatorio, existePunto: this.puntoObligatorio },
      },
      geolocation: null,
      tiposTdatoNovedad: TDATO_NOVEDAD,
      hasNFC: hasNFC(),
      showingDialogs: {
        scanQR: false,
      },
    }
  },
  watch: {
    geolocalizacion: {
      handler: function (newValue) {
        if (newValue) {
          this.$set(this.form, 'latitud', newValue.lat)
          this.$set(this.form, 'longitud', newValue.lng)
        }
      },
      deep: true,
    },
    puntoObligatorio (value) {
      this.formRules.punto.required = value
      this.formRules.punto.existePunto = value
    },
  },
  async created () {
    const resp = await Data.selectLookups(this)
    this.tnovedades = resp.data.selectTnovedad.result.dataset
    this.fuerzasSeguridad = resp.data.selectFuerzaSeguridad.result.dataset
    this.estados = resp.data.selectEstados.result.dataset
    extend('existePunto', {
      message: 'El punto no existe o no pertenece al puesto de servicio',
      validate: async (value) => {
        // volver a buscar el idpunto según el código scanner porque:
        // - se dispara el changePunto que asigna form.idpunto
        // - pero se puede ejecutar esta validación antes de que se haga la asignación de form.idpunto
        const datasetPuntos = await Data.selectPuntoCodigoScanner(this, value, this.idpuestoServicio)
        if (datasetPuntos.length > 0) {
          this.$set(this.form, 'idpunto', datasetPuntos[0].idpunto)
          return true
        } else {
          this.$set(this.form, 'idpunto', null)
          return false
        }
      }
    })
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.$set(this.form, 'fnovedad', formData.fnovedad)
      this.$set(this.form, 'tnovedad', _.find(this.tnovedades, { idtnovedad: formData.idtnovedad }))
      this.$set(this.form, 'idfuerza_seguridad', formData.idfuerza_seguridad)
      this.$set(this.form, 'estado', formData.estado)
      this.$set(this.form, 'informe_cliente', formData.informe_cliente)
      this.$set(this.form, 'descripcion', formData.descripcion)
      this.$set(this.form, 'latitud', formData.latitud)
      this.$set(this.form, 'longitud', formData.longitud)
      this.$set(this.form, 'idpunto', formData.idpunto)
      this.$set(this.form, 'punto', formData.punto_codigo_scanner)
      const [datasetTdatoNovedad] = await Data.selectTdatoNovedad(this, formData.idtnovedad)
      this.$set(this.form, 'datosNovedad', datasetTdatoNovedad)
      // valores de datos dinámicos
      const [datasetDatoLparteNovedad] = await Data.selectDatoLparteNovedad(this, id)
      for (const dato of datasetDatoLparteNovedad) {
        const datoLparteNovedadIndex = _.findIndex(this.form.datosNovedad, datoNovedad => datoNovedad.idtdato_novedad === dato.idtdato_novedad)
        if (datoLparteNovedadIndex >= 0) {
          this.$set(this.form.datosNovedad[datoLparteNovedadIndex], 'valor', dato.valor)
          // iddato_lparte_novedad para saber si es insert o update
          this.$set(this.form.datosNovedad[datoLparteNovedadIndex], 'iddato_lparte_novedad', dato.iddato_lparte_novedad)
        }
      }
    },
    async tnovedadSelected (value) {
      if (value) {
        // datos de tipo de novedad
        const [dataset] = await Data.selectTdatoNovedad(this, value.idtnovedad)
        this.$set(
          this.form,
          'datosNovedad',
          _.map(dataset, item => {
            item.valor = ''
            return item
          })
        )
        // fuerza de seguridad por defecto
        if (value.idfuerza_seguridad) {
          this.$set(this.form, 'idfuerza_seguridad', value.idfuerza_seguridad)
        } else {
          this.$set(this.form, 'idfuerza_seguridad', null)
        }
        // informe_cliente
        this.$set(this.form, 'informe_cliente', value.informe_cliente)
      } else {
        this.$set(this.form, 'datosNovedad', [])
        this.$set(this.form, 'idfuerza_seguridad', null)
        this.$set(this.form, 'informe_cliente', false)
      }
    },
    tdatoObligatorioRule (item) {
      if (item.obligatorio) {
        return [
          v => !!v || 'Campo requerido'
        ]
      } else {
        return []
      }
    },
    clickIrMapa (lat, lng) {
      window.open(getLatLongURL(lat, lng), '_blank')
    },
    async changePunto (value) {
      if (value) {
        const datasetPuntos = await Data.selectPuntoCodigoScanner(this, value, this.idpuestoServicio)
        if (datasetPuntos.length > 0) {
          this.$set(this.form, 'idpunto', datasetPuntos[0].idpunto)
        } else {
          this.$set(this.form, 'idpunto', null)
        }
      } else {
        this.$set(this.form, 'idpunto', null)
      }
    },
    clickQR () {
      this.showingDialogs.scanQR = !this.showingDialogs.scanQR
    },
    decodeQR (value) {
      if (value) {
        this.$set(this.form, 'punto', value)
        this.changePunto(value)
      }
    },
    async clickScanNFC () {
      this.$loading.showManual('Esperando lectura de etiqueta NFC...', true)
      try {
        const tag = await scanNFC()
        if (tag && tag.tagID) {
          this.$set(this.form, 'punto', tag.tagID)
          this.changePunto(tag.tagID)
          this.$alert.showSnackbarSuccess('Etiqueta NFC leída')
        } else {
          this.$alert.showSnackbarError('No se puede leer la etiqueta NFC')
        }
      } finally {
        this.$loading.hide()
      }
    },
  },
}
</script>

<style scoped>
.input-punto >>> input {
  -webkit-text-security: disc;
}
</style>
