import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectLookups (Vue) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo.addGT('estado', 0)
    apiCalls.push({ name: 'selectTnovedad', method: 'tnovedad.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectFuerzaSeguridad', method: 'fuerzaSeguridad.select', params: { filter: apiFilterEstadoActivo } })
    const apiFilterEstadoNoAnulado = new APIFilter()
    apiFilterEstadoNoAnulado.addGT('idlparte_novedad_estado', 0)
    apiCalls.push({ name: 'selectEstados', method: 'vLparteNovedadEstado.select', params: { filter: apiFilterEstadoNoAnulado } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectTdatoNovedad (Vue, idtnovedad) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
      .addExact('idtnovedad', idtnovedad)
    const resp = await Vue.$api.call('tdatoTnovedad.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectDatoLparteNovedad (Vue, idlparteNovedad) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
      .addExact('idlparte_novedad', idlparteNovedad)
    const resp = await Vue.$api.call('datoLparteNovedad.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('idlparte_novedad', id)
    const resp = await Vue.$api.call('lparteNovedad.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
  async selectPuntoCodigoScanner (Vue, codigoScanner, idpuestoServicio) {
    const apiFilter = new APIFilter()
    apiFilter
      .addGT('estado', 0)
      .addExact('codigo_scanner', codigoScanner)
      .addExact('idpuesto_servicio', idpuestoServicio)
    const respPunto = await Vue.$api.call('punto.select', { filter: apiFilter })
    return respPunto.data.result.dataset
  },
}
